<template>
	<div>
		<div class="topnav flex">
			<div class="emailbox flex">
				<div class="img1"></div>
				<div>support@softwareelf.com</div>
			</div>
			<div class="center-box flex">
				<div class="text">Excellent</div>
				<div class="img2"></div>
				<div class="text">29,259 reviews on</div>
				<div class="img3"></div>
			</div>
			<div class="linkbox flex">
				<div class="link point" v-for="item in linklistdata" :key="item.id" @click="tolink(item)">{{ item.title }}</div>
			</div>
		</div>
		<div class="comp-header flex">
			<div class="header-left flex point" @click="$router.push('/home')">
				<div class="h-left-logo">
					<img class="w-h-full" src="@/assets/images/header-logo.png" alt="" />
				</div>
				<!-- <div class="h-left-title">Software Elf</div> -->
			</div>
			<div class="header-center h-full flex-b">
				<div class="h-center-item flex-c" @click="$router.push('/home')">Home</div>
				<div @click="item.level_2.length && $router.push(`/goodsList?lv1id=${item.id}`)" v-for="(item, index) in homeProductList" :key="index" class="h-center-item flex-c">
					<div>{{ item.name }}</div>
					<!-- <div v-if="item.level_2.length" class="h-center-item-hover">
						<div v-for="(vItem, vIndex) in item.level_2" :key="vIndex" class="hover-item">
							<div class="hover-item-title" @click.stop="$router.push(`/goodsList?lv1id=${item.id}&lv2id=${vItem.id}`)">{{ vItem.name }}</div>
							<div class="hover-item-list">
								<div v-for="(wItem, wIndex) in vItem.goods_arr" @click.stop="$router.push(`/goodsdetail?id=${wItem.id}`)" :key="wIndex" class="hover-item-list-item">
									{{ wItem.title }}
								</div>
							</div>
						</div>
					</div> -->
				</div>
				<div class="h-center-item flex-c">OnlineChecking</div>
				<div class="h-center-item flex-c" style="white-space: nowrap">About Us</div>
			</div>
			<div class="header-right flex-e h-full">
				<div class="h-right-main flex-c h-full">
					<div @mouseenter="mouseenter" @mouseleave="mouseleave" class="icon-box h-full flex-c">
						<div class="icon point">
							<img class="w-h-full" src="@/assets/images/cart-icon.png" alt="" />
						</div>
						<div class="float-cart">
							<div class="header-bg">Shopping Cart</div>
							<div class="goods-list-wrap">
								<div class="goods-list">
									<div v-for="item in carList" :key="item.id" class="goods-item flex">
										<div class="img-box">
											<img class="h-full" :src="item?.goods?.image" alt="" />
										</div>
										<div class="right-box">
											<div class="title">
												{{ item?.goods?.title }}
											</div>
											<div class="stock">Stock: {{ item?.goods?.stocks }}</div>
											<div class="item-bottom-box flex-b">
												<div class="price">${{ item?.goods?.price }}</div>
												<div class="add-down-box flex">
													<div @click.stop="carDownOnClick(item)" class="btn-down"></div>
													<div class="btn-num btn-nums flex-c">
														<input class="common-input flex-c" type="text" v-model="item.nums" @input="carChangeOnClick($event, item)" />
													</div>
													<div @click.stop="carUpOnClick(item)" class="btn-up"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="bottom-box">
								<div class="line"></div>
								<div class="total-box flex-b">
									<div class="total">Total：</div>
									<div class="price">$ {{ carTotalPrice }}</div>
								</div>
								<div @click="checkOutOnClick" class="btn-box flex-e point">
									<div class="btn flex-c">Check Out</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="isLogin" @click="$router.push('/user')" class="flex point">
						<div class="avatar">
							<img class="w-h-full" :src="`${imgbaseUrl}${userInfo.avatar}`" alt="" />
						</div>
						<div class="name text-box">
							{{ userInfo?.username.length > 20 ? userInfo?.username.slice(0, 20) + '...' : userInfo?.username }}
						</div>
					</div>
					<div v-else class="text-box flex-c">
						<div class="point" @click="showLoginOverlay = true">Sign in</div>
						<div style="padding: 0 0.5em">/</div>
						<div class="point" @click="showRegisterOverlay = true">Register</div>
					</div>
				</div>
			</div>
			<van-overlay :show="showRegisterOverlay">
				<div class="overlay-box absolute-c">
					<div class="overlay-box-header flex-b">
						<div class="logo-box">
							<!-- <img class="w-h-full" src="@/assets/images/logo.png" alt="" /> -->
						</div>
						<div class="title">Registration</div>
						<div class="close-box point" @click="showRegisterOverlay = false">
							<img class="w-h-full" src="@/assets/images/overlay-close.png" alt="" />
						</div>
					</div>
					<div class="fuck-info-center flex-s">
						<div class="c-left">
							<div class="input-item">
								<div class="title">Email Address</div>
								<div class="wrap-box flex" style="margin-bottom: 0.1rem">
									<div class="input-box">
										<el-input v-model="regFormData.email" placeholder="input"></el-input>
									</div>
									<div class="input-btn-box flex-c">
										<div class="h-full flex-c point" @click="checkEmailOnClick">
											<div v-if="isCheckIng" class="check-img rotate360">
												<img class="w-h-full" src="@/assets/images/check-loading.png" alt="" />
											</div>
											<div v-else-if="isCheckOver" class="check-img">
												<img class="w-h-full" src="@/assets/images/check-over.png" alt="" />
											</div>
											<div v-else class="flex-c w-h-100">Verify email address</div>
										</div>
									</div>
								</div>
								<div style="margin-bottom: 0.1rem" class="text">Please enter an email address that can receive product keys.</div>
								<!-- <div class="text">Please enter an email address that can receive product keys.</div> -->
							</div>
							<div class="input-item">
								<div class="title">Password</div>
								<div class="input-box">
									<el-input type="password" v-model="regFormData.password" placeholder="Please enter your password"></el-input>
								</div>
							</div>
							<div class="input-item">
								<div class="title">Password</div>
								<div class="input-box">
									<el-input type="password" v-model="regFormData.password2" placeholder="Please enter your password again"></el-input>
								</div>
								<!-- <div class="textarea-box">
								<el-input resize="none" type="textarea" v-model="regFormData.password2" placeholder="input" rows="3"></el-input>
							</div> -->
							</div>
						</div>
						<!-- <div class="c-center"></div> -->
						<!-- <div class="c-right">Send your message and we will provide you with a VIP code to unlock 23 permissions, including redeeming more keys by points. Our ACE key will provide a 100% encrypted key supply service and share profits with all VIPs.</div> -->
						<div @click="regSubmitOnClick" class="rsg-btn flex-c point">SUBMIT</div>
					</div>
				</div>
			</van-overlay>
			<van-overlay :show="showLoginOverlay">
				<div class="overlay-box absolute-c">
					<div class="overlay-box-header flex-b">
						<div class="logo-box">
							<!-- <img class="w-h-full" src="@/assets/images/logo.png" alt="" /> -->
						</div>
						<div class="title">Log in</div>
						<div class="close-box point" @click="showLoginOverlay = false">
							<img class="w-h-full" src="@/assets/images/overlay-close.png" alt="" />
						</div>
					</div>
					<div class="fuck-info-center flex-s">
						<div class="c-left">
							<div class="input-item">
								<div class="title">Email address</div>
								<div class="input-box">
									<el-input v-model="loginFormData.account" placeholder="input"></el-input>
								</div>
							</div>
							<div class="input-item">
								<div class="title">password</div>
								<div class="input-box" style="margin-bottom: 0.12rem">
									<el-input type="password" v-model="loginFormData.password" placeholder="input"></el-input>
								</div>
								<div @click="forgetOnClick" class="text point">Forgot password?</div>
							</div>
						</div>
						<div @click="loginSubmitOnClick" class="rsg-btn flex-c point">SUBMIT</div>
					</div>
				</div>
			</van-overlay>
		</div>
	</div>
</template>

<script>
	import { getHomeProductApi, registerApi, loginApi, checkEmailApi, checkEmailTimeApi, changeCarNumApi } from '@/network/api.js';
	import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
	import { checkEmail } from '@/utils/util.js';
	import { throttle } from 'lodash';
	export default {
		data() {
			return {
				showRegisterOverlay: false,
				showLoginOverlay: false,
				// homeProductList: [],
				regFormData: {
					email: '',
					password: '',
					password2: ''
				},
				loginFormData: {
					account: '',
					password: ''
				},
				isCheckIng: false,
				isCheckOver: false,
				auth: '',
				linklistdata: [
					{
						id: 1,
						title: 'Terms of Tervice'
					},
					{
						id: 2,
						title: 'Pricacy Policy'
					},
					{
						id: 3,
						title: 'Delivery Policy'
					},
					{
						id: 4,
						title: 'Skype'
					}
				]
			};
		},
		computed: {
			...mapGetters(['isLogin']),
			...mapState(['userInfo', 'imgbaseUrl', 'carList', 'homeProductList']),
			carTotalPrice() {
				let totle = 0;
				this.carList.forEach((item) => {
					totle += item?.goods?.price * item?.nums;
				});
				console.log('购物车总价', totle);
				return totle.toFixed(1);
			}
		},
		mounted() {
			this.init();
		},
		watch: {
			showRegisterOverlay: {
				handler(newVal, oldVal) {
					this.isCheckIng = false;
					this.isCheckOver = false;
					clearInterval(this.Timer);
				}
			}
		},
		methods: {
			tolink(v) {
				if (v.id == 1 || v.id == 2 || v.id == 3) {
					this.$router.push({
						path: '/Serves',
						query: {
							id: v.id
						}
					});
				} else {
				}
			},
			...mapActions(['getCartList', 'mergeCartList']),
			...mapMutations(['setUserInfo', 'setHomeProductList']),
			init() {
				this.getHomeProductFun();
				if (this.isLogin) {
					this.getCartList();
				}
			},
			mouseenter() {
				document.querySelectorAll('.float-cart')[0].style.height = '4.9rem';
			},
			mouseleave() {
				document.querySelectorAll('.float-cart')[0].style.height = '0rem';
			},
			checkOutOnClick() {
				if (!this.carList.length) {
					this.$message.error('Shopping cart is empty');
					return;
				}
				this.$router.push('/cartDetail');
			},
			forgetOnClick() {
				this.$router.push('/forgetStepOne');
				this.showLoginOverlay = false;
			},
			async getHomeProductFun() {
				const params = {};
				const res = await getHomeProductApi(params);
				// this.homeProductList = res.data;
				this.setHomeProductList(res?.data || []);
				console.log('获取的首页产品', res);
			},
			async regSubmitOnClick() {
				if (this.regFormData.password !== this.regFormData.password2) {
					this.$message.error('Two passwords are inconsistent');
					return;
				}
				console.log('表单数据', this.regFormData);
				const res = await registerApi({ ...this.regFormData, auth: this.auth });
				if (res.code === 1) {
					this.showRegisterOverlay = false;
					this.setUserInfo(res.data.userinfo);
					this.mergeCartList();
				}
				console.log('注册结果', res);
			},
			async loginSubmitOnClick() {
				console.log('表单数据', this.loginFormData);
				const res = await loginApi(this.loginFormData);
				if (res.code === 1) {
					this.showLoginOverlay = false;
					this.setUserInfo(res.data.userinfo);
					this.mergeCartList();
				}
				console.log('登录结果', res);
			},
			async checkEmailOnClick() {
				console.log('checkEmailOnClick');
				if (!checkEmail(this.regFormData.email)) {
					this.$message.error('Please enter the correct email address');
					return;
				}
				const params = {
					email: this.regFormData.email,
					type: 1
				};
				const res = await checkEmailApi(params);
				if (res.code === 1) {
					this.$message.success('The verification email was successfully sent. Please go to your email to check');
					this.isCheckIng = true;
					this.Timer = setInterval(() => {
						this.checkEmailTimeFun();
					}, 3000);
					this.Timer2 = setTimeout(() => {
						this.$message.success('Verification timeout');
						clearInterval(this.Timer);
					}, 30000 * 30);
				}
				console.log('checkEmailOnClick', res);
			},
			async checkEmailTimeFun() {
				const params = {
					email: this.regFormData.email,
					type: 1
				};
				const res = await checkEmailTimeApi(params);
				if (res.code === 1) {
					this.$message.success('Verification successful');
					this.isCheckIng = false;
					this.isCheckOver = true;
					clearTimeout(this.Timer2);
					clearInterval(this.Timer);
					this.auth = res.data.token;
				}
				console.log('checkEmailOnClick', res);
			},
			async carDownOnClick(item) {
				if (item.nums > 1) {
					item.nums--;
					this.changeNums(item, item.nums);
				}
			},
			async carChangeOnClick(e, item) {
				item.nums = e.target.value;
				if (!item.nums || item.nums == '0') {
					item.nums = 1;
				}
				this.changeNums(item, item.nums);
			},
			async carUpOnClick(item) {
				item.nums++;
				this.changeNums(item, item.nums);
			},
			changeNums: throttle(async function (item, num) {
				console.log('changeNums');
				if (this.isLogin) {
					const params = {
						id: item.id,
						nums: num,
						type: 'nums'
					};
					try {
						await changeCarNumApi(params);
						await this.getCartList();
					} catch (error) {}
				}
			}, 3000)
		}
	};
</script>

<style lang="scss">
	.topnav {
		width: 100%;
		height: 0.4rem;
		background: #fff;
		padding: 0 1.2rem 0 1rem;
		justify-content: space-between;
		box-sizing: border-box;
		font-family: Microsoft YaHei UI, Microsoft YaHei UI;
		font-weight: 400;
		font-size: 0.14rem;
		color: #333333;
		.emailbox {
			height: 0.25rem;
			font-family: Microsoft YaHei UI, Microsoft YaHei UI;
			font-weight: 400;
			font-size: 0.14rem;
			color: #000000;
			flex: 1;
			.img1 {
				width: 0.14rem;
				height: 0.14rem;
				background: url('~@/assets/images/header-bg1.png') no-repeat;
				background-size: 100% 100%;
				margin: 0.04rem;
			}
		}
		.center-box {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			.img2 {
				width: 0.89rem;
				height: 0.16rem;
				background: url('~@/assets/images/header-bg2.png') no-repeat;
				background-size: 100% 100%;
				margin-right: 0.08rem;
			}
			.img3 {
				width: 0.66rem;
				height: 0.16rem;
				background: url('~@/assets/images/header-bg3.png') no-repeat;
				background-size: 100% 100%;
				margin-right: 0.08rem;
			}
			.text {
				margin-right: 0.08rem;
				font-weight: 500;
				font-size: 0.16rem;
				color: #333333;
			}
		}
		.linkbox {
			flex: 1;
			display: flex;
			// margin-left: 1rem;
			.link {
				// padding: 0 0.1rem;
				// flex: 1;
				white-space: nowrap;
				margin-left: 0.7rem;
			}
		}
	}
	.comp-header {
		width: 100%;
		height: 1.1rem;
		background: #cde9f8;
		padding: 0 1.2rem 0 1.2rem;
		box-sizing: border-box;
		font-weight: 400;
		font-size: 0.18rem;
		color: #666666;
		.header-left {
			margin-right: 0.85rem;
			.h-left-logo {
				width: 2.73rem;
				height: 0.5rem;
				// margin-right: 0.2rem;
			}
		}
		.header-center {
			.h-center-item {
				font-size: 0.18rem;
				// min-width: 1rem;
				padding: 0 0.37rem;
				height: 100%;
				position: relative;
				z-index: 99;
				.h-center-item-hover {
					position: absolute;
					top: 0.6rem;
					left: 0;
					width: 6.6rem;
					// width: 2rem;
					height: 0rem;
					overflow: hidden;
					background: #fff;
					transition: all 0.3s;
					// padding: 0.4rem 0.32rem;
					display: flex;
					align-items: flex-start;
					flex-wrap: wrap;
					// opacity: 0.5;
					padding: 0rem 0.32rem;
					.hover-item {
						width: 50%;
						flex-shrink: 0;
						text-align: left;
						.hover-item-title {
							font-weight: 500;
							font-size: 0.2rem;
							color: #333;
							margin-bottom: 0.14rem;
						}
						.hover-item-title:hover {
							color: #4182a4;
						}
						.hover-item-list {
							.hover-item-list-item {
								color: #666666;
								margin-bottom: 0.2rem;
							}
							.hover-item-list-item:nth-last-of-type(1) {
								margin-bottom: 0.44rem;
							}
							.hover-item-list-item:hover {
								color: #4182a4;
							}
						}
					}
				}
			}
			.h-center-item:hover {
				cursor: pointer;
				font-weight: 600;
				color: #000;
				box-sizing: border-box;
				.h-center-item-hover {
					// height: 2rem;
					// min-height: 1rem;
					height: fit-content;
					max-height: 10rem;
					overflow-y: auto;
					// padding: 0.4rem 0.32rem;
					// opacity: 1;
					padding: 0.4rem 0.32rem;
					box-shadow: 0px 0.04rem 0.3rem 0px rgba(0, 0, 0, 0.15);
				}
			}
		}
		.header-right {
			flex-grow: 1;
			.h-right-main {
				.icon-box {
					position: relative;
					z-index: 99;
					.icon {
						width: 0.24rem;
						height: 0.24rem;
						margin-right: 0.59rem;
						padding-left: 2rem;
					}
					.float-cart {
						position: absolute;
						right: 0.23rem;
						top: 0.6rem;
						width: 3.85rem;
						height: 0rem;
						overflow: hidden;
						transition: all 0.3s;
						box-shadow: 0px 0.2rem 0.4rem 0px rgba(0, 0, 0, 0.3);
						border-radius: 0.04rem;
						.header-bg {
							width: 100%;
							height: 0.8rem;
							background: url('~@/assets/images/float-cart-top.png') no-repeat;
							background-size: 100% 100%;
							padding-top: 0.36rem;
							padding-left: 0.32rem;
							box-sizing: border-box;
							font-weight: 500;
							font-size: 0.2rem;
						}
						.goods-list-wrap {
							width: 100%;
							background: #fff;
							padding: 0.2rem 0.2rem;
							box-sizing: border-box;
							overflow-y: auto;
							height: 2.89rem;
							.goods-list {
								width: 100%;
								.goods-item {
									width: 100%;
									// height: 0.7rem;
									// background: pink;
									margin-bottom: 0.24rem;
									.img-box {
										width: 0.7rem;
										height: 0.7rem;
										padding: 0.04rem;
										box-sizing: border-box;
										margin-right: 0.12rem;
										flex-shrink: 0;
										display: flex;
										align-items: center;
										justify-content: center;
									}
									.right-box {
										flex-grow: 1;
										.title {
											margin-bottom: 0.1rem;
											color: #333333;
										}
										.stock {
											margin-bottom: 0.1rem;
											font-size: 0.12rem;
											color: #666666;
										}
										.item-bottom-box {
											.add-down-box {
												width: 0.72rem;
												height: 0.2rem;
											}
											.price {
												color: #333;
											}
										}
									}
								}
							}
						}

						.bottom-box {
							padding: 0rem 0.2rem 0.2rem 0.2rem;
							box-sizing: border-box;
							width: 100%;
							min-height: 1rem;
							background: #fff;

							.line {
								width: 100%;
								height: 0.01rem;
								background: #d9e6ed;
								margin-bottom: 0.2rem;
							}
							.total-box {
								margin-bottom: 0.2rem;
								.total {
									color: #000;
								}
								.price {
									font-weight: 500;
									font-size: 0.18rem;
									color: #333333;
								}
							}
							.btn-box {
								width: 100%;
								.btn {
									width: 1.65rem;
									height: 0.38rem;
									background: #4182a4;
									color: #fff;
									font-weight: 500;
									font-size: 0.14rem;
								}
							}
						}
					}
				}
				.icon-box:hover {
					.float-cart {
						height: 4.9rem;
					}
				}
				.avatar {
					width: 0.4rem;
					height: 0.4rem;
					border-radius: 50%;
					overflow: hidden;
					margin-right: 0.06rem;
				}
				.text-box {
					font-family: Source Sans Pro, Source Sans Pro;
					font-weight: 400;
					font-size: 0.18rem;
					color: #000000;
					white-space: nowrap;
				}
			}
		}
		.overlay-box {
			width: 6.8rem;
			min-height: 3rem;
			background: #fff;
			box-shadow: 0px 0.06rem 0.3rem 0px rgba(0, 0, 0, 0.2), 0px -0.01rem 0.04rem 0px rgba(0, 0, 0, 0.2);
			border-radius: 0.1rem;
			.overlay-box-header {
				width: 100%;
				height: 0.84rem;
				padding: 0 0.38rem 0 0.6rem;
				box-sizing: border-box;
				background: #7bb9d9;
				border-radius: 0.1rem 0.1rem 0 0;
				.logo-box {
					width: 0.44rem;
					height: 0.44rem;
				}
				.title {
					font-weight: 500;
					font-size: 0.32rem;
				}
				.close-box {
					width: 0.22rem;
					height: 0.22rem;
				}
			}
			.fuck-info-center {
				padding: 0.3rem 0.6rem 0.4rem 0.6rem;
				box-sizing: border-box;
				width: 100%;
				height: 100%;
				.c-left {
					margin-bottom: 0.4rem;
					.wrap-box {
						height: 100%;
						// .el-input__inner {
						// 	border-radius: 0.04rem 0 0 0.04rem;
						// }
						.input-item {
							flex-grow: 1;
						}
						.input-btn-box {
							width: 1.96rem;
							height: 0.44rem;
							flex-shrink: 0;
							background: #4182a4;
							font-weight: 500;
							font-size: 0.14rem;
							color: #ffffff;
							.check-img {
								width: 0.28rem;
								height: 0.28rem;
							}
						}
					}
					.input-item {
						margin-bottom: 0.3rem;
						.title {
							color: #333333;
							margin-bottom: 0.12rem;
						}
						.input-box {
							width: 100%;
							height: 0.44rem;
							border: 0.01rem solid #b3cddb;
						}
						.text {
							font-size: 0.12rem;
							color: #666666;
						}
					}
				}
			}
			.rsg-btn {
				width: 100%;
				height: 0.44rem;
				background: #4182a4;
				color: #fff;
				font-weight: 500;
			}
		}
	}
</style>
