<template>
	<div class="view-wrap-index">
		<Header ref="headerRef" />
		<div class="view-router-box">
			<router-view></router-view>
		</div>
		<Footer ref="footerRef" />
	</div>
</template>

<script>
	import Header from './comp/header.vue';
	import Footer from './comp/footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		name: 'Index'
	};
</script>

<style lang="scss">
	.view-router-box {
		min-height: calc(100vh - 3.82rem);
	}
</style>
