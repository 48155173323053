<template>
	<div class="comp-footer">
		<div class="center">
			<div class="textlist">
				<div class="bigtitle">Information</div>
				<div class="linktext" v-for="item in linktext1" :key="item.id" @click="totext(item.id)">{{ item?.title }}</div>
			</div>
			<div class="textlist">
				<div class="bigtitle">Customer Service</div>
				<div class="linktext" v-for="item in linktext2" :key="item.id">{{ item.text }}</div>
			</div>
			<div class="textlist">
				<div class="bigtitle">Extras</div>
				<div class="linktext">Affiliate</div>
			</div>
			<div class="textlist">
				<div class="bigtitle">My Account</div>
				<div class="linktext" v-for="item in linktext3" :key="item.id">{{ item.text }}</div>
			</div>
			<div class="textlist">
				<div class="bigtitle">Follow Us</div>
				<div class="iconbox">
					<div class="icon point" v-for="item in iconlist" :key="item.id" @click="toicon(item.herf)">
						<img :src="item.img" alt="" />
					</div>
				</div>
			</div>
			<!-- <div class="lbox">
				<div class="bigtitile">E-Mail</div>
				<div class="itembox">support@softwareelf.com</div>
				<div class="itembox point policy" @click="topolicy">Privacy Policy</div>
			</div>
			<div class="cbox">
				<div class="bigtitile">Payment method</div>
				<div class="payimgbox">
					<img src="../../../assets/images/usdt-icon.png" alt="" />
				</div>
				<div class="itembox">Copyright© 2024 Software Elf. All Rights Reserved.</div>
			</div>
			<div class="rbox" @click="Feedbackshow = !Feedbackshow">
				<div class="iconbox">
					<img src="./../../../assets/images/pc-foot-feedback.png" alt="" />
				</div>
				<div>Feedback</div>
			</div> -->
		</div>
		<!-- <div class="Feedbackbox" v-show="Feedbackshow">
			<div class="titlehead">
				<div class="tcenter">
					<div>User feedback</div>
					<img src="../../../assets/images/overlay-close.png" alt="" @click="Feedbackshow = false" />
				</div>
			</div>
			<div class="content">
				<div class="name">Email address</div>
				<input class="putbox" type="text" v-model="Emailvalue" placeholder="Please enter your e-mail address" />
				<div class="name">Feedback content</div>
				<textarea class="putbox put2" v-model="textareaContent" rows="4" cols="50" placeholder="Please enter the content you need to feedback" />
			</div>
			<div class="btnbox point" @click="submitvalu">Submit feedback</div>
		</div> -->
	</div>
</template>

<script>
	import { submitFeedbackApi, getfoootlistApi } from '../../../network/api';
	export default {
		data() {
			return {
				Feedbackshow: false,
				Emailvalue: '',
				textareaContent: '',

				linktext1: [],

				linktext2: [
					{
						id: 1,
						text: 'Contact Us'
					},
					{
						id: 2,
						text: 'Site Map'
					}
				],
				linktext3: [
					{
						id: 1,
						text: 'My Account'
					},
					{
						id: 2,
						text: 'Order History'
					},
					{
						id: 3,
						text: 'Wish List'
					},
					{
						id: 4,
						text: 'Newsletter'
					}
				],
				iconlist: [
					{
						id: 1,
						img: require('../../../assets/images/fbicon.png')
					},
					{
						id: 2,
						img: require('../../../assets/images/ticon.png'),
						herf: 'https://x.com/elfsoftwarekey'
					},
					{
						id: 3,
						img: require('../../../assets/images/inicon.png')
					}
				]
			};
		},
		created() {
			this.getlistdata();
		},
		methods: {
			toicon(v) {
				if (v) {
					window.open(v);
				}
			},
			submitvalu() {
				if ((this.Emailvalue == '', this.textareaContent == '')) {
					this.$message({
						message: 'Please enter email and content',
						type: 'error'
					});
				} else {
					let params = {
						email: this.Emailvalue,
						content: this.textareaContent
					};
					submitFeedbackApi(params).then((res) => {
						console.log(res);
						if (res.code == 1) {
							this.$message({
								message: res.msg,
								type: 'success'
							});
							this.Feedbackshow = false;
						}
					});
				}
			},
			// topolicy() {
			// 	this.$router.push('/Pact');
			// },
			async getlistdata() {
				const res = await getfoootlistApi();
				this.linktext1 = res.data.Information;
			},
			totext(v) {
				console.log(v);
				this.$router.push({
					path: '/Pact',
					query: {
						id: v
					}
				});
			}
		}
	};
</script>

<style lang="scss">
	.comp-footer {
		width: 100%;
		height: 3.8rem;
		background: #cce9f8;
		position: relative;
		bottom: 0;
		.center {
			width: 13.4rem;
			margin: 0 auto;
			padding-top: 0.62rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.textlist {
				width: 1.7rem;
				height: 0.2rem;
				text-align: left;
				font-weight: 500;
				font-size: 0.16rem;
				color: #8594ab;
				line-height: 0.16rem;
				box-sizing: border-box;
				.bigtitle {
					font-weight: 500;
					font-size: 0.2rem;
					color: #000000;
					line-height: 0.2rem;
					margin-bottom: 0.4rem;
					cursor: pointer;
				}
				.linktext {
					margin: 0.1rem 0;
					cursor: pointer;
				}
				.iconbox {
					display: flex;
					justify-content: center;
					padding-left: 0.15rem;
					.icon {
						margin-right: 0.36rem;
						img {
							width: 0.32rem;
						}
					}
				}
			}
			// .bigtitile {
			// 	font-size: 0.2rem;
			// 	margin-bottom: 0.14rem;
			// }
			// .itembox {
			// 	font-size: 0.16rem;
			// 	margin-bottom: 0.2rem;
			// }
		}

		.Feedbackbox {
			position: absolute;
			width: 5rem;
			border-radius: 0.04rem;
			height: 4.58rem;
			background: #ffffff;
			box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.2), 0px -1px 4px 0px rgba(0, 0, 0, 0.2);
			bottom: 3rem;
			right: 3.25rem;
			.titlehead {
				width: 100%;
				height: 0.64rem;
				background: #7bb9d9;
				border-radius: 0.04rem 0.04rem 0 0;
				.tcenter {
					width: 4.4rem;
					height: 0.64rem;
					margin: 0 auto;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-weight: 500;
					font-size: 0.24rem;
					color: #ffffff;
					img {
						width: 0.28rem;
						cursor: pointer;
					}
				}
			}
			.content {
				width: 4.3rem;
				margin: 0 auto;
				.name {
					font-weight: 400;
					font-size: 0.16rem;
					color: #333333;
					margin-bottom: 0.12rem;
					margin-top: 0.12rem;
				}
				.putbox {
					width: 100%;
					height: 0.44rem;
					background: #ffffff;
					border-radius: 0px 0px 0px 0px;
					border: 0.01rem solid #b3cddb;
					margin-bottom: 0.3rem;
					font-weight: 400;
					font-size: 0.16rem;
					color: #999999;
				}
				.put2 {
					height: 1.2rem;
					resize: none;
				}
			}
			.btnbox {
				width: 4.4rem;
				height: 0.44rem;
				margin: 0 auto;
				background: #4182a4;
				font-weight: 500;
				font-size: 0.14rem;
				line-height: 0.44rem;
				color: #ffffff;
				text-align: center;
			}
		}
	}
</style>
